<template>
  <v-data-table
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title=""
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        hide-edit
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.cluster_name="{ item }">
      <router-link
        :to="{
          name: 'definitions.clusters.edit',
          params: {
            id: item.cluster_id,
          },
        }"
      >
        {{ item.cluster_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.door_number="{ item }">
      <router-link
        :to="{
          name: 'incomes.houses.show',
          params: {
            id: item.house_id,
          },
        }"
      >
        {{ item.door_number }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.payment_count="{ item }">
      <router-link
        v-if="item.payment_count"
        :to="{
          name: 'activities.cc-payments.list',
          query: {
            user_card_subscription_id: item.id,
            is_subscription: 'true',
          },
        }"
      >
        {{ item.payment_count }}
      </router-link>
      <span v-else>0</span>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.created_at="{ value }">
      <rs-table-cell-date show-time :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.failed_attempts="{ value }">
      <rs-table-cell-number
        :class="value >= 3 ? 'text-danger font-weight-bold' : ''"
        :value="value"
      />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";
import { mapGetters } from "vuex";

export default {
  name: "AdminSubscriptionList",
  mixins: [hasDataTable],
  props: {
    userId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    userId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["paymentProviderList"]),
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "TYA",
          value: "cluster_name",
          searchable: "text",
        },
        {
          text: "Blok",
          value: "block_name",
          searchable: "text",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
        },
        {
          text: "Ödeme Tedarikçisi",
          value: "payment_provider_name",
          searchable: "multiselect",
          searchValue: "payment_provider_ids",
          options: () => this.paymentProviderList,
        },
        {
          text: "KK No",
          value: "masked_cc_no",
          searchable: "text",
        },
        {
          text: "İsim",
          value: "name",
          searchable: "text",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
          width: "40px",
        },
        {
          text: "Ödeme",
          value: "payment_count",
          searchable: "number",
          align: "end",
          notPrice: true,
        },
        {
          text: "Aylık Başarısız Deneme",
          value: "failed_attempts",
          searchable: "number",
          align: "end",
          notPrice: true,
        },
        {
          text: "Oluşturma",
          value: "created_at",
          searchable: "date",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.userId) {
        params.user_id = this.userId;
      }

      return this.$api
        .query("api-super-admin/subscriptions", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
